<template>
  <div class="Tests">
    <span
      lang="ru"
      style="position: relative;
  display: block;
  padding: 20px;
  border-radius: 12px;
  background-color: crimson;
  width: 95%;
  max-width: 600px;
  margin: 0% auto;
  hyphens: auto;"
      >Если был произведен перенос компетенций / откат компетенций, требуется
      обновление данной страницы, чтобы получить обновленные данные
      пользователей</span
    >

    <div class="Buttons" v-if="!ButtonsBlocked">
      <template v-if="UsersList.length == 0">
        <GradientButton
          ButtonText="Загрузить список пользователей"
          @click.native="getUsers"
        />
      </template>
      <template v-else>
        <GradientButton
          ButtonText="Проверить ачивки пользователей"
          @click.native="checkAchievements"
        />
        <GradientButton
          ButtonText="Проверить монеты пользователей"
          @click.native="checkCoins"
        />
      </template>
    </div>

    <details class="Advanced noselect">
      <summary>Дополнительные настройки запросов (Опционально)</summary>
      <div class="Buttons" v-if="!ButtonsBlocked">
        <div class="Block">
          <label for="TickTimeout"
            >Время, через которое будет выполнен новый запрос после получения
            ответа от предыдущего (в мс)</label
          >
          <input type="number" v-model="TickSleep" />
        </div>

        <div class="Block">
          <label for="UsersPerTick"
            >Количество пользователей, которое будет обработано за один
            запрос</label
          >
          <input type="number" v-model="UsersPerTick" />
        </div>
      </div>
    </details>

    <div class="Console">
      <template v-if="ConsoleCacheList.length == 0">
        <span style="width: 100%; text-align: center; color: #535353;"
          >Здесь пусто</span
        >
      </template>
      <template v-else>
        <span
          v-for="(text, tindx) in ConsoleCacheList"
          :key="`${text}-${tindx}`"
          >{{ text }}</span
        >
      </template>
    </div>
  </div>
</template>

<script>
import {
  getAllUsersChecker,
  postCheckUsersAchievements,
  postCheckUsersCoins,
} from "@/api/system_checker.js";

import GradientButton from "@/components/CustomElements/ButtonGradient.vue";

export default {
  name: "AdminCheckCoinsAndAchievements",
  components: { GradientButton },
  data() {
    return {
      ConsoleCacheList: [],
      UsersList: [],

      UsersPerTick: 50,
      TickSleep: 800,

      ButtonsBlocked: false,
      IterationNumber: 0,
      LastIteration: false,
      IterationData: {},
    };
  },
  async created() {},
  watch: {},
  computed: {
    UsersListWithCells() {
      return this.UsersList.filter((x) => x.user_cells.length > 0);
    },
  },
  methods: {
    async resetIteration() {
      this.ConsoleCacheList = [];
      this.IterationNumber = 0;
      this.IterationData = {};
      this.LastIteration = false;
    },

    async getUsers() {
      await this.resetIteration();

      this.ConsoleCacheList = ["Get all users started..."];
      this.ButtonsBlocked = true;

      await this.getUsersData();
    },
    async getUsersData() {
      this.IterationNumber++;

      if (this.LastIteration) {
        this.ConsoleCacheList.push(
          `Users count total: ${this.UsersList.length} `
        );
        this.ConsoleCacheList.push("Get users finished.");

        this.UsersPerTick = 50;
        this.TickSleep = 1500;

        this.ButtonsBlocked = false;
        return 0;
      }

      this.ConsoleCacheList.push(`Iteration number: ${this.IterationNumber}`);

      let timeStart = new Date().getTime();
      let _users_list_resp = await getAllUsersChecker(
        this.IterationNumber,
        this.UsersPerTick
      );
      let timeEnd = new Date().getTime();
      let MeasureTime = timeEnd - timeStart;

      this.ConsoleCacheList.push(
        `Getted ${_users_list_resp.data.length} users (${MeasureTime} ms)`
      );
      if (_users_list_resp.data.length < this.UsersPerTick) {
        this.LastIteration = true;
      }

      this.UsersList = this.UsersList.concat(_users_list_resp.data);

      if (this.LastIteration) {
        await this.getUsersData();
      } else {
        setTimeout(async () => {
          await this.getUsersData();
        }, this.TickSleep);
      }
    },

    async checkAchievements() {
      await this.resetIteration();

      this.ConsoleCacheList = ["Achievements check started..."];
      this.ButtonsBlocked = true;

      this.IterationData = {
        updatedUsersCount: 0,
      };

      await this.sendAchievementsData();
    },
    async sendAchievementsData() {
      this.IterationNumber++;

      if (this.LastIteration) {
        this.ConsoleCacheList.push(
          `Updated users: ${this.IterationData.updatedUsersCount} / ${this.UsersList.length}`
        );

        this.ConsoleCacheList.push("Achievements check finished.");

        this.ButtonsBlocked = false;
        return 0;
      }

      if (this.UsersPerTick * this.IterationNumber >= this.UsersList.length) {
        this.LastIteration = true;
        this.ConsoleCacheList.push(
          `Iteration number: ${this.IterationNumber} (Last)`
        );
      } else {
        this.ConsoleCacheList.push(`Iteration number: ${this.IterationNumber}`);
      }

      let req_data = {
        users: this.UsersList.slice(
          this.UsersPerTick * (this.IterationNumber - 1),
          this.UsersPerTick * this.IterationNumber
        ),
      };
      let timeStart = new Date().getTime();
      let _achievements_resp = await postCheckUsersAchievements(req_data);
      let timeEnd = new Date().getTime();

      let MeasureTime = timeEnd - timeStart;

      this.ConsoleCacheList.push(
        _achievements_resp.data === 0
          ? `No users updated (${MeasureTime} ms)`
          : `Updated achievements for ${_achievements_resp.data} users (${MeasureTime} ms)`
      );

      this.IterationData.updatedUsersCount += _achievements_resp.data;

      if (this.LastIteration) {
        await this.sendAchievementsData();
      } else {
        setTimeout(async () => {
          await this.sendAchievementsData();
        }, this.TickSleep);
      }
    },

    async checkCoins() {
      await this.resetIteration();

      this.ConsoleCacheList = ["Coins check started..."];
      this.ConsoleCacheList.push(
        `Users with cells: ${this.UsersListWithCells.length}`
      );
      this.ButtonsBlocked = true;

      this.IterationData = {
        updatedUsersCount: 0,
      };

      await this.sendCoinsData();
    },
    async sendCoinsData() {
      this.IterationNumber++;

      if (this.LastIteration) {
        this.ConsoleCacheList.push(
          `Updated users: ${this.IterationData.updatedUsersCount} / ${this.UsersListWithCells.length}`
        );

        this.ConsoleCacheList.push("Coins check finished.");

        this.ButtonsBlocked = false;
        return 0;
      }

      if (this.UsersPerTick * this.IterationNumber >= this.UsersList.length) {
        this.LastIteration = true;
        this.ConsoleCacheList.push(
          `Iteration number: ${this.IterationNumber} (Last)`
        );
      } else {
        this.ConsoleCacheList.push(`Iteration number: ${this.IterationNumber}`);
      }

      let req_data = {
        users: this.UsersListWithCells.slice(
          this.UsersPerTick * (this.IterationNumber - 1),
          this.UsersPerTick * this.IterationNumber
        ),
      };
      let timeStart = new Date().getTime();
      let _achievements_resp = await postCheckUsersCoins(req_data);
      let timeEnd = new Date().getTime();

      let MeasureTime = timeEnd - timeStart;

      this.ConsoleCacheList.push(
        _achievements_resp.data === 0
          ? `No users updated (${MeasureTime} ms)`
          : `Updated coins for ${_achievements_resp.data} users (${MeasureTime} ms)`
      );

      this.IterationData.updatedUsersCount += _achievements_resp.data;

      if (this.LastIteration) {
        await this.sendCoinsData();
      } else {
        setTimeout(async () => {
          await this.sendCoinsData();
        }, this.TickSleep);
      }
    },
  },
};
</script>

<style scoped>
.Advanced {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  margin: 0% auto;

  width: fit-content;
  height: fit-content;
}
.Advanced > summary {
  position: relative;
  display: block;

  margin: 0% auto;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
}

/* Убираем стандартный маркер Chrome */
.Advanced > summary::-webkit-details-marker {
  display: none;
}
/* Убираем стандартный маркер Firefox */
.Advanced > summary {
  list-style: none;
}

/* Добавляем собственный маркер для закрытого состояния */
.Advanced > summary:before {
  content: "\21A0";
  margin-right: 7px;
}

/* Добавляем собственный маркер для открытого состояния */
.Advanced[open] > summary:before {
  content: "\21A1";
}

.Tests {
  position: relative;

  padding-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;

  padding: 50px 8vw;

  width: 100%;
  min-height: 100vh;

  color: white;
}
.Buttons {
  position: relative;
  display: flex;

  padding: 30px 0%;

  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;

  width: fit-content;
  height: fit-content;

  margin: 0% auto;
}

.Buttons > .Block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  width: fit-content;
  height: auto;
  flex-basis: auto;
}
.Buttons > .Block > label {
  position: relative;
  display: block;

  width: 300px;
  height: fit-content;
  text-align: left;

  margin-bottom: auto;
}

.Console {
  position: relative;
  display: flex;

  margin: 0% auto;

  padding: 60px 10px 10px 10px;

  width: 500px;
  height: 500px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  background-color: #1a1a1a;
  overflow-y: auto;
  border-radius: 12px;
}

.Console > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  text-align: left;
}

.Console::after {
  content: "Console";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #464646;
  text-align: left;
  padding: 10px;
}
</style>
